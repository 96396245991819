// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import React, { useState, useRef } from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import capitalize from 'lodash/capitalize'
import { textFieldStyles } from '../styles/styleProps'
import SubmitButton from './SubmitButton'
import validatePresence from '../lib/validatePresence'
import NavigationLink from './NavigationLink'
import AuthenticityToken from './AuthenticityToken'
import StyledTextField from './StyledTextField'

const StyledFormControlLabel = withStyles({
  label: {
    color: 'rgba(255, 255, 255, 0.75);',
    fontSize: '14px',
    lineHeight: '24px',
  },
  root: {
    color: 'white',
  },
})(FormControlLabel)
const StyledCheckbox = withStyles({
  root: {
    padding: 0,
    marginRight: 8,
    marginLeft: 8,
    color: 'white',
    '&$checked': {
      color: 'white',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

const getDefaultUserName = () =>
  window.document.cookie
    .split(';')
    .map((pair) => pair.split('='))
    .reduce((accum, [key, value]) => ({ ...accum, [key.trim()]: value }), {
      username: undefined,
    }).username || ''

const separationStyles = {
  height: 8,
  width: '200%',
}
const submitWaitTimeout = 1000

type Props = {
  onSubmit: () => void
  ossProvider: string
  paths: any
  resourceName: string
  userField: string
}

const LoginForm = ({
  onSubmit,
  ossProvider,
  paths,
  resourceName,
  userField,
}: Props) => {
  const [errors, setErrors] = useState({})
  const [emailFieldValue, setEmailFieldValue] = useState(getDefaultUserName())
  const [passwordFieldValue, setPasswordFieldValue] = useState('')
  const [isSubmitted, setSubmitted] = useState(false)
  const formRef = useRef(null)

  /* @ts-expect-error auto-src: strict-conversion */
  const addError = (field, message) => {
    /* @ts-expect-error auto-src: strict-conversion */
    if (errors[field] !== message) {
      /* @ts-expect-error auto-src: strict-conversion */
      errors[field] = message
      setErrors(errors)
    }
  }

  const formHasErrors = () => {
    const emailError = validatePresence(userField, emailFieldValue)
    const passwordError = validatePresence('password', passwordFieldValue)

    setErrors({
      email: emailError,
      password: passwordError,
    })

    return emailError || passwordError
  }

  /* @ts-expect-error auto-src: strict-conversion */
  const handleFormSubmit = (event) => {
    if (formHasErrors()) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      window.document.cookie = `username=${emailFieldValue}; path='/'`

      // Add a small timeout to allow the spinner animation to start
      // on Safari before navigating away
      if (!isSubmitted) {
        onSubmit()
        setSubmitted(true)
        setTimeout(() => {
          /* @ts-expect-error auto-src: strict-conversion */
          formRef.current.submit()
        }, submitWaitTimeout)
        event.preventDefault()
      }
    }
  }

  /* @ts-expect-error auto-src: strict-conversion */
  const handleEmailChange = (event) => {
    const { value } = event.target

    if (value) {
      setEmailFieldValue(value)
      const error = validatePresence('email', value)

      addError('email', error)
    }
  }

  /* @ts-expect-error auto-src: strict-conversion */
  const handlePasswordChange = (event) => {
    const { value } = event.target

    if (value) {
      setPasswordFieldValue(value)
      const error = validatePresence('password', value)

      addError('password', error)
    }
  }

  const userFieldName = () => {
    if (ossProvider === 'ldap') {
      return userField
    }

    return `${resourceName}[${userField}]`
  }

  const passwordFieldName = () => {
    if (ossProvider === 'ldap') {
      return 'password'
    }

    return `${resourceName}[password]`
  }

  const showForgotPassword = () => {
    if (ossProvider === 'ldap') {
      return null
    }

    return (
      <NavigationLink href={paths.newPassword}>Forgot password?</NavigationLink>
    )
  }

  return (
    <form
      ref={formRef}
      action={paths.createSession}
      autoComplete="off"
      method="post"
      onSubmit={handleFormSubmit}
    >
      <AuthenticityToken />
      <input
        autoFocus
        name="oss_provider"
        type="hidden"
        value={ossProvider || ''}
      />
      <StyledTextField
        /* @ts-expect-error auto-src: non-strict-conversion */
        className={textFieldStyles.className}
        defaultValue={getDefaultUserName()}
        FormHelperTextProps={{
          /* @ts-expect-error auto-src: non-strict-conversion */
          error: Boolean(errors.email),
        }}
        /* @ts-expect-error auto-src: non-strict-conversion */
        helperText={errors.email}
        label={capitalize(userField)}
        name={userFieldName()}
        onBlur={handleEmailChange}
        onChange={handleEmailChange}
      />
      <div style={separationStyles} />
      <StyledTextField
        /* @ts-expect-error auto-src: non-strict-conversion */
        className={textFieldStyles.className}
        FormHelperTextProps={{
          /* @ts-expect-error auto-src: non-strict-conversion */
          error: Boolean(errors.password),
        }}
        /* @ts-expect-error auto-src: non-strict-conversion */
        helperText={errors.password}
        label="Password"
        name={passwordFieldName()}
        onBlur={handlePasswordChange}
        onChange={handlePasswordChange}
        type="password"
      />
      <SubmitButton label="SIGN IN" />
      <StyledFormControlLabel
        /* @ts-expect-error auto-src: non-strict-conversion */
        control={<StyledCheckbox name="user[remember_me]" />}
        label="Remember me"
      />
      {showForgotPassword()}
    </form>
  )
}

export default LoginForm
