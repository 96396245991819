// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { getCSRFToken } from 'common/lib/csrf'

const AuthenticityToken = () => (
  <input name="authenticity_token" type="hidden" value={getCSRFToken() || ''} />
)

export default AuthenticityToken
