import Promise from 'es6-promise'
import WebpackerReact from 'webpacker-react'
import EditInvitation from '../components/EditInvitation'
import EditPassword from '../components/EditPassword'
import EditRegistration from '../components/EditRegistration'
import LockedAccount from '../components/LockedAccount'
import Login from '../components/Login'
import ResetPassword from '../components/ResetPassword'
import UnauthorizedNavigation from '../components/UnauthorizedNavigation'
import SsoLogout from '../components/SsoLogout'

Promise.polyfill()
WebpackerReact.setup({
  EditInvitation,
})
WebpackerReact.setup({
  EditPassword,
})
WebpackerReact.setup({
  EditRegistration,
})
WebpackerReact.setup({
  LockedAccount,
})
WebpackerReact.setup({
  Login,
})
WebpackerReact.setup({
  ResetPassword,
})
WebpackerReact.setup({
  SsoLogout,
})
WebpackerReact.setup({
  UnauthorizedNavigation,
})
