import { blue, blueGrey, grey, amber, yellow } from '@material-ui/core/colors'
import { navbarHeight } from './navbar'
// This file contains just key/value pairs. They are not necessarily style definitions
// Note that some of constants defined here are used in lib/customTheme and apply across Material UI components.
// As much as possible use the Material UI colors as imported above and described at
//   http://www.material-ui.com/#/customization/colors .
// When adding custom colors form outside the Material UI palette, name them here
// using color names that are useful for humans.
// You can use this site to help you pick a name:
//   http://chir.ag/projects/name-that-color/
// or if it's too hard, name it with functionality (e.g. "redError").
// It's kind of cheating, but it's been done before.
export const CustomColors = {
  alizarinCrimson: '#E01F27',
  alto: '#D5D5D5',
  astronaut: '#2C4474',
  blueChambray: '#324d7b',
  bluePolo: '#7aa9cf',
  blueSanMarino: '#3f68a8',
  blueVisualDirectoryBranding: '#4971ab',
  blueRibbon: '#0C6BEC',
  burntUmber: '#97362A',
  darkDoveGray: '#616161',
  darkSilverChalice: '#aeaeae',
  darkBlue: 'rgba(49, 78, 126, 0.6)',
  dodgerBlue: '#25A7F1',
  doveGray: '#737373',
  gallery: '#ecebeb',
  greenHaze: '#00a166',
  greenJade: '#00b572',
  greyAvatarBorder: '#d2d2d2',
  iron: '#dde1e2',
  lightBlueVisualDirectoryBranding: '#6185ba',
  lightPersianRed: '#d0332a',
  mercury: '#e5e5e5',
  persianRed: '#cc372c',
  presenceActive: '#53D558',
  redError: '#f44336',
  redPunch: '#db4437',
  rhino: '#273659',
  roman: '#D65F56',
  sanMarino: '#3A70AD',
  silver: '#CACACA',
  silverChalice: '#b1b1b1',
  whisper: '#eaeaea',
  bombay: '#AEAFB1',
  frenchPass: '#ceeefd',
  lightGrey: '#575757',
  boulder: '#757575',
  mediumGrey: '#727273',
  coldBlue: '#4670AE',
  concrete: '#F2F2F2',
  sanGrey: '#878787',
  shark: '#202022',
  mineShaft: '#333333',
  tundora: '#4D4D4D',
  pomegranate: '#F53F2C',
  waikawaGray: '#56698F',
  whiteSmoke: '#F8F8F8',
  monza: '#D0021B',
}
// temporary. should be replaced, per
// https://www.pivotaltracker.com/story/show/141835943
const backwardsCompatibleColor = {
  primary: CustomColors.blueVisualDirectoryBranding,
  primaryAction: CustomColors.greenJade,
  vacateButton: CustomColors.redPunch,
  homepageIcon: CustomColors.blueChambray,
  homepageBackground: blueGrey[50],
  // #ECEFF1
  headingBackground: grey[100],
  // #F5F5F5
  avatarBorder: CustomColors.greyAvatarBorder,
  avatarShadow: grey[500],
  activeBooking: CustomColors.greenJade,
  canceledBooking: grey[100],
  declinedBooking: CustomColors.redPunch,
  expiredBooking: grey[400],
  bookingsTheme: CustomColors.greenJade,
  bookingsBackground: grey[200],
  favoriteFill: '#ffd740',
  // #FFD740
  favoriteBorder: amber[400],
  // #FFCA28
  nonfavoriteFill: grey[500],
  pendingBooking: CustomColors.greenJade,
}
const colorPalette = {
  primary: CustomColors.blueVisualDirectoryBranding,
  secondaryFlow: CustomColors.greenJade,
  action: {
    primaryAction: CustomColors.greenJade,
    reverseAction: CustomColors.redPunch, // vacateButton
  },
  disabled: grey[400],
  // #BDBDBD
  warning: CustomColors.monza,
  appLoader: {
    circularProgress: grey[400],
  },
  homepage: {
    homepageIcon: CustomColors.blueChambray,
    homepageBackground: blueGrey[50], // #ECEFF1
  },
  deskBooking: {
    activeBooking: CustomColors.greenJade,
    bookingsBackground: grey[200],
    bookingsStandard: grey[600],
    bookingsTheme: CustomColors.greenJade,
    canceledBooking: grey[500],
    declinedBooking: CustomColors.redPunch,
    expiredBooking: grey[400],
    lightGrey: grey[500],
    pendingBooking: CustomColors.greenJade,
    systemBooking: 'rgba(213, 213, 213, 0.5)',
  },
  directory: {
    selectedRow: CustomColors.whisper,
    tableHighlight: grey[100], // #F5F5F5
  },
  favorites: {
    favoriteFill: '#ffd740',
    favoriteBorder: amber[400],
    // #FFCA28
    nonfavoriteFill: grey[500],
  },
  lists: {
    headingBackground: grey[100],
    // #F5F5F5
    selectedItemBackground: grey[200],
    // #eeeeee
    selectedTextColor: blue[700],
  },
  leases: {
    secondary: CustomColors.redPunch,
    error: CustomColors.redPunch,
    defaultAction: CustomColors.greenJade,
    defaultActionHovered: CustomColors.greenHaze,
    secondaryAction: CustomColors.redPunch,
    secondaryActionHovered: CustomColors.persianRed,
    textSecondary: CustomColors.boulder,
    textPrimary: CustomColors.mineShaft,
  },
  avatarBorder: CustomColors.greyAvatarBorder,
  branding: {
    bookableByRequest: {
      occupiedBorder: CustomColors.blueSanMarino,
      occupiedFill: CustomColors.bluePolo,
      vacantBorder: yellow[700],
    },
    bookableSelfService: {
      occupiedBorder: CustomColors.blueSanMarino,
      occupiedFill: CustomColors.bluePolo,
      vacantBorder: CustomColors.greenHaze,
    },
    freeAddress: {
      available: {
        border: CustomColors.greenHaze,
      },
      neutral: {
        border: CustomColors.darkDoveGray,
      },
      occupied: {
        border: CustomColors.lightPersianRed,
      },
      standby: {
        border: yellow[700],
      },
    },
    // Visual Directory
    browsePanel: {
      selectedTabTextColor: '#FFFFFF',
      unselectedTabTextColor: 'rgba(255,255,255, .6)',
      // this is 60% opacity of the default selectedTabTextColor
      underlineColor: '#FFFFFF',
      alphabetSelectorLetterColor: blue[400],
      // #42a5f5
      tabBackgroundColor: CustomColors.lightBlueVisualDirectoryBranding,
    },
    kioskMarker: {
      borderColor: CustomColors.pomegranate,
      fillColor: CustomColors.pomegranate,
    },
    portal: {
      markerColor: '#42A5F5',
      primaryColor: '#42A5F5',
    },
    roomBookingStatus: {
      available: {
        color: '#01B572',
      },
      availableSoon: {
        color: '#F5A623',
      },
      reserved: {
        color: '#D63B30',
      },
    },
  },
  homeItemBorder: CustomColors.iron,
  presenceActive: CustomColors.presenceActive,
  link: blue[700],
  // #1976d2
  detailsTabs: {
    active: blue[400],
    // #42a5f5
    background: grey[100],
    // #F5F5F5
    inactive: grey[500],
    // #9e9e9e
    closeButton: CustomColors.silverChalice,
  },
  resourcesInspector: {
    seatOverwritten: CustomColors.redPunch,
    detailText: CustomColors.lightGrey,
    hoveredText: CustomColors.dodgerBlue,
    editButton: CustomColors.coldBlue,
    subTitle: CustomColors.mediumGrey,
    badge: CustomColors.coldBlue,
    icon: CustomColors.sanGrey,
    placedholderBackground: CustomColors.concrete,
    placedholderLightBackground: CustomColors.alto,
  },
  scenarioQueue: {
    titleText: CustomColors.lightGrey,
    employeeName: CustomColors.shark,
    secondaryText: CustomColors.doveGray,
    ambiguous: CustomColors.astronaut,
    darkAmbiguous: CustomColors.rhino,
    lightAmbiguous: CustomColors.waikawaGray,
    notFound: CustomColors.persianRed,
    darkNotFound: CustomColors.burntUmber,
    lightNotFound: CustomColors.roman,
  },
  highlight: blue[400],
  // #42a5f5
  lightGrey: CustomColors.bombay,
  divider: CustomColors.concrete,
  cancel: CustomColors.alizarinCrimson,
}
const styleConstants = {
  color: backwardsCompatibleColor,
  colorPalette,
  buttonBar: {
    iconBorderColor: grey[300],
    iconFillColor: CustomColors.sanMarino,
  },
  icon: {
    iconSize: 24,
    bigIconSize: 48,
    iconColor: grey[600],
    // #757575
    instructionSheetIconColor: grey[500], // #9e9e9e
  },
  navbar: {
    staticFontSize: 20,
    dynamicFontSize: 16,
    secondaryFlowFontSize: 18,
    superscriptFontSize: '67%',
    padding: 15,
    height: navbarHeight,
    lineHeight: 42,
    heightWithSearch: 80,
    standard: {
      background: CustomColors.blueVisualDirectoryBranding,
      fontColor: '#FFFFFF',
      iconColor: '#FFFFFF',
    },
    details: {
      background: grey[100],
      fontColor: grey[800],
      iconColor: grey[600],
    },
    input: {
      background: CustomColors.lightBlueVisualDirectoryBranding,
    },
    logoIcon: {
      height: 20,
    },
    highlighted: {
      color: CustomColors.blueRibbon,
    },
  },
  navMenu: {
    paddingX: 15,
  },
  font: {
    family: 'Open Sans, sans-serif',
    size: {
      standard: 13,
      prominent: 15,
      heading: 16,
      detailHeading: 18,
      maxDetailHeading: 20,
      smallHeading: 12,
      // fieldLabel: details fields
      fieldLabel: 12,
      // default value for size of formLabel
      formLabel: 16,
      // default value for size of formLabel when in focus. 75% of non-focussed size
      formLabelFocus: 12,
    },
    weight: {
      light: 300,
      normal: 400,
      semibold: 600,
      bold: 700,
      extraBold: 800,
    },
    color: {
      link: colorPalette.link,
      error: CustomColors.redError,
      input: '#000000',
      // #000
      // standard: resource descriptions, paper subtext
      standard: grey[700],
      // #616161
      // minorHeading: detail resource sublabel, icons, list secondary text
      minorHeading: grey[600],
      // #757575
      // heading: details navbar text, detail card resource label, field values
      heading: grey[800],
      // #424242
      // majorHeading: lists headings, list primary text, home (paper) primary text
      majorHeading: grey[900],
      // #212121
      // formLabel: hotdesk form, request manager form
      formLabel: grey[600],
      // #757575
      // fieldLabel: details fields
      fieldLabel: grey[500],
      // #9e9e9e
      bookingsStandard: grey[600],
      canceledBooking: colorPalette.deskBooking.canceledBooking,
      declinedBooking: '#FFFFFF',
      detailValue: colorPalette.deskBooking.lightGrey,
      cancel: CustomColors.redPunch,
      subHeaderFontColor: CustomColors.darkBlue,
    },
  },
  form: {
    underlineColor: grey[300],
    // #e0e0e0
    searchInputBackgroundColor: '#FFFFFF',
  },
  button: {
    zoom: {
      backgroundColor: grey[100],
      // #F5F5F5
      dividerColor: grey[300],
      // #e0e0e0
      fontIconColor: grey[500],
      // #9e9e9e
      fontIconColorHover: grey[600], // #757575
    },
    alternateAction: {
      backgroundColor: grey[50],
      // #FAFAFA
      textColor: '#000000',
      textSize: 14,
      fontIconColor: grey[500], // #9e9e9e
    },
  },
  infoCards: {
    // we now call this a (resource) summary sheet
    mini: {},
    details: {
      resourceIconSize: 88,
      employeePhotoSize: 150,
      horizontalPadding: 20,
    },
    maximizedDetails: {
      resourceIconSize: 90,
      employeePhotoSize: 170,
      horizontalPadding: 28,
    },
  },
  employeePhoto: {
    default: {
      size: 100,
    },
  },
  resourceIcon: {
    default: {
      size: 50,
    },
    extended: {
      size: 44,
    },
    small: {
      size: 24,
    },
  },
  roomPhoto: {
    default: {
      height: 166,
      width: '100%',
    },
  },
  labels: {
    textLabel: {
      fontFamily: 'Roboto',
    },
    floorOverlayLabel: {
      fontFamily: 'Univers 45 Light Bold',
    },
    floorNameLabel: {
      fontFamily: 'Oswald',
    },
    readonly: {
      textColor: grey[400],
    },
  },
  floorPicker: {
    icon: grey[400],
    openColor: CustomColors.blueChambray,
    transparentGrey: 'rgba(0, 0, 0, 0.38)',
    lightGrey: CustomColors.bombay,
  },
  dockedCard: {
    boxShadow: '-1px 1px 2px 0 rgba(0,0,0,0.2)',
  },
  legend: {
    width: 270,
    transform: 'translateX',
    transitionDuration: '0.3s',
    openingTransitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
    closingTransitionTimingFunction: 'cubic-bezier(0.4, 0, 0.6, 1)',
    padding: 20,
  },
  highlightedSearchResult: CustomColors.frenchPass,
  placeholder: {
    secondaryColor: CustomColors.gallery,
  },
  actionBar: {
    backgroundColor: CustomColors.tundora,
    width: 54,
  },
  moveChip: {
    height: 24,
    maxWidth: 140,
  },
  employeeQueues: {
    buttonBarColor: CustomColors.lightGrey,
    cardBackgroundColor: CustomColors.whiteSmoke,
    active: blue[400],
  },
  multiSelect: {
    primary: CustomColors.blueVisualDirectoryBranding,
    lightGrey: CustomColors.lightGrey,
    unchecked: CustomColors.silver,
  },
}

export default styleConstants
