// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import React, { useState, useRef } from 'react'
import nameFromField from '../lib/nameFromField'
import validatePresence from '../lib/validatePresence'
import AuthenticityToken from './AuthenticityToken'
import ConfirmationField from './ConfirmationField'
import HttpMethodInput from './HttpMethodInput'
import InstructionsText from './InstructionsText'
import NavigationLink from './NavigationLink'
import PasswordTextField from './PasswordTextField'
import SubmitButton from './SubmitButton'
import StyledTextField from './StyledTextField'

type Props = {
  firstName: string
  initials: string
  invitationToken: string
  lastName: string
  onSubmit: () => void
  passwordValidationProps: any
  paths: any
  serverErrors?: any
}

const EditInvitationForm = ({
  firstName,
  initials,
  invitationToken,
  lastName,
  onSubmit,
  passwordValidationProps,
  paths,
  serverErrors,
}: Props) => {
  const [errors, setErrors] = useState(serverErrors || {})
  const [firstNameValue, setFirstNameValue] = useState(firstName)
  const [lastNameValue, setLastNameValue] = useState(lastName)
  const [initialsValue, setInitialsValue] = useState(initials)
  const confirmationFieldRef = useRef(null)
  const passwordFieldRef = useRef(null)

  /* @ts-expect-error auto-src: strict-conversion */
  const addError = (field, message) => {
    if (errors[field] !== message) {
      errors[field] = message
      setErrors(errors)
    }
  }

  const formHasErrors = () => {
    const firstNameError = validatePresence('first name', firstNameValue)
    const lastNameError = validatePresence('last name', lastNameValue)
    const initialsError = validatePresence('initials', initialsValue)
    /* @ts-expect-error auto-src: strict-conversion */
    const confirmationError = confirmationFieldRef.current.errors(true)
    /* @ts-expect-error auto-src: strict-conversion */
    const isPasswordValid = passwordFieldRef.current.validate()

    setErrors({
      first_name: firstNameError,
      last_name: lastNameError,
      initials: initialsError,
      password_confirmation: confirmationError,
    })

    return (
      !isPasswordValid ||
      firstNameError ||
      lastNameError ||
      initialsError ||
      confirmationError
    )
  }

  /* @ts-expect-error auto-src: strict-conversion */
  const handleFormSubmit = (event) => {
    if (formHasErrors()) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      onSubmit()
    }
  }

  /* @ts-expect-error auto-src: strict-conversion */
  const handleRequiredFieldChange = (event) => {
    const { value } = event.target

    if (value) {
      const name = nameFromField(event.target)

      if (name === 'first_name') {
        setFirstNameValue(value)
      }

      if (name === 'last_name') {
        setLastNameValue(value)
      }

      if (name === 'initials') {
        setInitialsValue(value)
      }

      const error = validatePresence(name, value)

      addError(name, error)
    }
  }

  /* @ts-expect-error auto-src: strict-conversion */
  const handleConfirmationValidation = (error) => {
    addError('password_confirmation', error)
  }

  /* @ts-expect-error auto-src: strict-conversion */
  const handlePasswordChange = (event) => {
    /* @ts-expect-error auto-src: strict-conversion */
    confirmationFieldRef.current.handleOriginalFieldChange(event)
  }

  return (
    <form
      action={paths.update}
      autoComplete="off"
      method="post"
      onSubmit={handleFormSubmit}
    >
      <AuthenticityToken />
      <HttpMethodInput />
      <input
        name="user[invitation_token]"
        type="hidden"
        value={invitationToken}
      />
      <InstructionsText>Welcome to OfficeSpace!</InstructionsText>
      <StyledTextField
        /* @ts-expect-error auto-src: non-strict-conversion */
        defaultValue={firstName}
        FormHelperTextProps={{
          error: Boolean(errors.first_name),
        }}
        helperText={errors.first_name}
        label="First name"
        name="user[first_name]"
        onBlur={handleRequiredFieldChange}
        onChange={handleRequiredFieldChange}
      />
      <StyledTextField
        /* @ts-expect-error auto-src: non-strict-conversion */
        defaultValue={lastName}
        FormHelperTextProps={{
          error: Boolean(errors.last_name),
        }}
        helperText={errors.last_name}
        label="Last name"
        name="user[last_name]"
        onBlur={handleRequiredFieldChange}
        onChange={handleRequiredFieldChange}
      />
      <StyledTextField
        /* @ts-expect-error auto-src: non-strict-conversion */
        defaultValue={initials}
        FormHelperTextProps={{
          error: Boolean(errors.initials),
        }}
        helperText={errors.initials}
        label="Initials"
        name="user[initials]"
        onBlur={handleRequiredFieldChange}
        onChange={handleRequiredFieldChange}
      />
      <PasswordTextField
        ref={passwordFieldRef}
        initialError={errors.password}
        name="user[password]"
        onChange={handlePasswordChange}
        /* @ts-expect-error auto-src: non-strict-conversion */
        validationProps={passwordValidationProps}
      />
      <ConfirmationField
        ref={confirmationFieldRef}
        FormHelperTextProps={{
          error: Boolean(errors.password_confirmation),
        }}
        helperText={errors.password_confirmation}
        humanIdentifier="password confirmation"
        label="Password confirmation"
        name="user[password_confirmation]"
        onValidate={handleConfirmationValidation}
        originalFieldPlural="passwords"
        type="password"
      />
      <SubmitButton label="REGISTER" />
      <NavigationLink href={paths.newSession}>Sign In</NavigationLink>
    </form>
  )
}

export default EditInvitationForm
