// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import ResourceFormPage from './ResourceFormPage'
import EditPasswordForm from './EditPasswordForm'

/* @ts-expect-error auto-src: noflow */
const EditPassword = (props) => (
  <ResourceFormPage {...props}>{EditPasswordForm}</ResourceFormPage>
)

export default EditPassword
