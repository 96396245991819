import merge from 'lodash/merge'
import { createMuiTheme } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'
import styleConstants from '../components/styles/definitions'

const backgroundTransition =
  'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'

export const torchRed = '#ff001f'
export const persianRed = '#cc372c'
export const darkerOssPrimary = '#324e7e'
export const formBackground = '#f4f4f4'
export const pictonBlue = blue[400]
export const customPalettes = {
  // OSS
  oss: {
    main: styleConstants.colorPalette.primary,
    contrastText: '#fff',
  },
  ossLight: {
    main: pictonBlue,
    contrastText: '#fff',
  },
  danger: {
    main: torchRed,
    contrastText: '#fff',
  },
  // darker
  dangerDark: {
    main: persianRed,
    contrastText: '#fff',
  },
  ossDark: {
    main: darkerOssPrimary,
    contrastText: '#fff',
  },
  white: {
    main: '#fff',
    contrastText: '#fff',
  },
}
export const baseTheme = {
  palette: {
    common: {
      persianRed,
    },
    primary: customPalettes.oss,
    secondary: customPalettes.ossLight,
    error: customPalettes.danger,
    highlight: pictonBlue,
  },
  typography: {
    fontFamily: 'Open Sans',
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: `2px solid ${pictonBlue}`,
        },
      },
    },
    MuiListItemIcon: {
      root: {
        marginRight: 0,
      },
    },
  },
}
export const mainTheme = merge({}, baseTheme, {
  overrides: {
    MuiAutocomplete: {
      option: {
        fontSize: 12,
      },
    },
    MuiBottomNavigationAction: {
      root: {
        transition: 'none',
      },
      label: {
        fontSize: 12,
        fontWeight: 400,
        transition: 'none',
        '&selected': {
          fontSize: 13,
        },
      },
    },
    MuiButton: {
      root: {
        minWidth: 88,
        borderRadius: 2,
        '&+&': {
          marginLeft: 16,
        },
      },
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 16,
        },
      },
    },
    MuiDialogContentText: {
      root: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 13,
      },
    },
    MuiExpansionPanel: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: '8px 16px',
      },
    },
    MuiExpansionPanelSummary: {
      content: {
        margin: '16px 0',
        '&$expanded': {
          margin: '16px 0',
        },
      },
      root: {
        '&$expanded': {
          minHeight: 'auto',
        },
        minHeight: 'auto',
        padding: '0 16px',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 10,
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 10,
        lineHeight: '1.6',
        minHeight: 32,
        '&$focused': {
          color: 'rgba(0, 0, 0, 0.54) !important',
        },
      },
    },
    MuiInput: {
      input: {
        padding: '6px 8px',
        '&$disabled': {
          cursor: 'not-allowed',
        },
      },
      multiline: {
        padding: '6px 8px',
        '& textarea': {
          minHeight: 20,
          overflowY: 'auto',
          padding: 0,
        },
      },
      root: {
        '&$disabled': {
          cursor: 'not-allowed',
        },
        backgroundColor: formBackground,
        borderRadius: 2,
        fontSize: 12,
        lineHeight: '1.6',
        minHeight: 32,
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid transparent',
        },
        '&:hover:not(.disabled):before': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
        },
      },
    },
    MuiInputLabel: {
      formControl: {
        fontSize: 13,
        left: -5,
        marginLeft: 6,
        pointerEvents: 'none',
        top: -10,
        transform: 'translate(5px, 32px) scale(1)',
        zIndex: 2,
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginRight: 4,
        maxHeight: 'none',
      },
    },
    MuiListItem: {
      container: {
        display: 'flex',
        alignItems: 'center',
      },
      root: {
        flexGrow: 1,
      },
    },
    MuiListItemText: {
      dense: {
        fontSize: '0.7500rem',
        padding: 0,
        whiteSpace: 'nowrap',
      },
      secondary: {
        fontSize: '0.625rem !important', // Should be MuiListItemText-secondary.MuiListItemText-textDense
      },
      multiline: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 12,
        minHeight: 24,
        padding: '4px 16px',
      },
    },
    MuiSelect: {
      root: {
        '&$disabled': {
          color: 'rgba(0, 0, 0, 0.26)',
          cursor: 'not-allowed',
          '&:hover': {
            backgroundColor: formBackground,
          },
        },
      },
      icon: {
        color: '#575757',
        height: 24,
        width: 24,
      },
      selectMenu: {
        lineHeight: 'inherit',
        paddingRight: 8,
        transition: backgroundTransition,
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        height: 16,
        width: 16,
      },
    },
    MuiTooltip: {
      tooltip: {
        maxWidth: 220,
      },
    },
  },
})
const darkMainTheme = merge({}, mainTheme, {
  palette: {
    primary: customPalettes.ossDark,
  },
})
const dangerOverrides = merge({}, mainTheme, {
  palette: {
    primary: customPalettes.danger,
    secondary: customPalettes.dangerDark,
  },
})

export const darkOssTheme = createMuiTheme(darkMainTheme)
export const dangerTheme = createMuiTheme(dangerOverrides)
/* @ts-expect-error auto-src: non-strict-conversion */
export const ossTheme = createMuiTheme(mainTheme)
export default ossTheme
