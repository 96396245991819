import { deepOrange, orange, amber, lime } from '@material-ui/core/colors'

export const userDefinedWords = ['officespacesoftware', 'officespace']
export const editableFieldNames = [
  'first_name',
  'last_name',
  'initials',
  'password',
  'current_password',
  'password_confirmation',
]
export const PASSWORD_STRENGHTS = {
  PUNY: 'Puny',
  WEAK: 'Weak',
  MEDIOCRE: 'Mediocre',
  GOOD: 'Good',
  GREAT: 'Great',
}
export const passwordStrengthsList = [
  PASSWORD_STRENGHTS.PUNY,
  PASSWORD_STRENGHTS.WEAK,
  PASSWORD_STRENGHTS.MEDIOCRE,
  PASSWORD_STRENGHTS.GOOD,
  PASSWORD_STRENGHTS.GREAT,
]
export const passwordScoreColors = {
  [PASSWORD_STRENGHTS.PUNY]: deepOrange[200],
  [PASSWORD_STRENGHTS.WEAK]: orange[200],
  [PASSWORD_STRENGHTS.MEDIOCRE]: amber[500],
  [PASSWORD_STRENGHTS.GOOD]: lime[500],
  [PASSWORD_STRENGHTS.GREAT]: '#aeea00',
}
