// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import React from 'react'
import { ThemeProvider } from '@material-ui/styles'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import { baseTheme } from 'common/themes/materialTheme'

/* @ts-expect-error auto-src: non-strict-conversion */
const muiTheme = createMuiTheme(baseTheme)

export default (props: any) => <ThemeProvider theme={muiTheme} {...props} />
