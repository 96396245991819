// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-unused-vars */
import PropTypes from 'prop-types'
import React from 'react'
import NotificationsCenter from './NotificationsCenter'
import { editableFieldNames } from './lib'
import ThemeProvider from './ThemeProvider'

export default class ResourceFormPage extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    children: PropTypes.func.isRequired,
    dialogText: PropTypes.string,
    errors: PropTypes.object,
    serverFeedback: PropTypes.string,
  }

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    dialogText: 'Please wait...',
  }

  /* @ts-expect-error auto-src: noflow */
  constructor(props, context) {
    super(props, context)
    /* @ts-expect-error auto-src: noflow */
    const errors = { ...this.props.errors }
    // The errors that correspond to a field are presented as errors on the field.
    // The ones that do not correspond to any field are presented as notifications.
    const activeFeedback = Object.keys(errors || {})
      .filter((key) => editableFieldNames.indexOf(key) === -1)
      .map((key) => {
        const error = errors[key]

        delete errors[key]

        return error
      })
      /* @ts-expect-error auto-src: noflow */
      .concat((this.props.serverFeedback && [this.props.serverFeedback]) || [])
      .join(' ')

    this.state = {
      activeFeedback,
      formErrors: errors,
    }
  }

  handleSubmit = () => {
    /* @ts-expect-error auto-src: noflow */
    this.notificationsRef.openProgressDialog()
  }

  render() {
    /* @ts-expect-error auto-src: noflow */
    const { errors, serverFeedback, ...rest } = this.props

    return (
      <ThemeProvider>
        <NotificationsCenter
          ref={(notifications) => {
            /* @ts-expect-error auto-src: noflow */
            this.notificationsRef = notifications
          }}
          /* @ts-expect-error auto-src: noflow */
          dialogText={this.props.dialogText}
          /* @ts-expect-error auto-src: noflow */
          serverFeedback={this.state.activeFeedback}
        />
        {/* @ts-expect-error auto-src: noflow */}
        <this.props.children
          {...rest}
          onSubmit={this.handleSubmit}
          /* @ts-expect-error auto-src: noflow */
          serverErrors={this.state.formErrors}
        />
      </ThemeProvider>
    )
  }
}
