// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { CircularProgress, Dialog } from '@material-ui/core'
import { mediumWhite } from '../styles/additionalColors'

const styles = {
  body: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#809dbd',
    borderRadius: '2px',
    color: mediumWhite,
    fontSize: '15px',
    position: 'relative',
    width: 190,
    padding: 20,
    overflow: 'hidden',
  },
  text: {
    marginLeft: '22px',
  },
}

type Props = {
  dialogText: string
  open: boolean
}

const OverlayProgressDialog = ({ dialogText, open }: Props) => (
  <Dialog open={open}>
    {/* @ts-expect-error auto-src: non-strict-conversion */}
    <div style={styles.body}>
      <CircularProgress size={33} thickness={3} />
      <div style={styles.text}>{dialogText}</div>
    </div>
  </Dialog>
)

export default OverlayProgressDialog
