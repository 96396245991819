import moment from 'moment-timezone'
import Timekeeper from 'timekeeper'

type TestUtils = {
  bookingManager: {
    undoTimeout?: number
  }
  mockedFlags: { [key: string]: boolean }
  moment: typeof moment
  pendingRequestsCount: number
  requestsBlocked: boolean
  roomScreens: {
    crestronLightBarColor?: string
    pinCodeExpirationTimeout?: number
    pollInterval?: number
    startPolling?: (pollInterval: number) => void
    stopPolling?: () => void
  }
  stackPlans: {
    seatBlockingCursorThrottle?: number
  }
  Timekeeper: typeof Timekeeper
  vd: {
    myEventsRefreshInterval?: number
  }
}

declare global {
  interface OSS {
    testUtils?: TestUtils
    afterInitTestUtils?: () => void
    mockedFlags?: { [key: string]: boolean }
    // eslint-disable-next-line camelcase
    image_url: (args: string) => string
    VD_URL?: string
  }

  interface Window {
    oss: OSS
  }
}

window.oss = {
  ...window.oss,
  testUtils: {
    bookingManager: {},
    mockedFlags: {},
    moment,
    pendingRequestsCount: 0,
    requestsBlocked: false,
    roomScreens: {},
    stackPlans: {},
    Timekeeper,
    vd: {},
  },
}

if (window.oss?.afterInitTestUtils) {
  window.oss.afterInitTestUtils()
}
