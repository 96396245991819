import { deepOrange } from '@material-ui/core/colors'
import { chambray } from './additionalColors'

export const errorColoring = {
  color: deepOrange[200],
}
export const instructions = {
  color: chambray,
  fontSize: '13px',
  fontWeight: '600',
  marginBottom: '-13px',
  marginTop: '22px',
  paddingBottom: '14px',
  textAlign: 'center',
}
export const submitButton = {
  margin: '32px 0',
}
export const navigationLink = {
  float: 'right',
  lineHeight: '24px',
}
export const stackedMenuButton = {
  margin: '16px 0',
}
