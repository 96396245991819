// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import PropTypes from 'prop-types'
import React from 'react'
import NotificationsCenter from './NotificationsCenter'
import UnlockForm from './UnlockForm'

export default class UnlockAccount extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    serverFeedback: PropTypes.string,
  }

  handleSubmit = () => {
    /* @ts-expect-error auto-src: noflow */
    this.notificationsRef.openProgressDialog()
  }

  render() {
    /* @ts-expect-error auto-src: noflow */
    const { serverFeedback, ...rest } = this.props

    return (
      <>
        <NotificationsCenter
          ref={(notifications) => {
            /* @ts-expect-error auto-src: noflow */
            this.notificationsRef = notifications
          }}
          dialogText="Please wait..."
          serverFeedback={serverFeedback}
        />
        <UnlockForm
          {...rest}
          ref={(form) => {
            /* @ts-expect-error auto-src: noflow */
            this.formRef = form
          }}
          onSubmit={this.handleSubmit}
        />
      </>
    )
  }
}
