// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import React, { useRef } from 'react'
import dismissibleSnackbarProps from '../lib/dismissibleSnackbarProps'
import ThemeProvider from './ThemeProvider'
import NotificationsCenter from './NotificationsCenter'
import LoginForm from './LoginForm'

type Props = {
  paths: any
  serverFeedback?: string
  ossProvider: string
  resourceName: string
  userField: string
}

const Login = ({
  serverFeedback,
  ossProvider,
  paths,
  resourceName,
  userField,
}: Props) => {
  const notificationsRef = useRef(null)

  const snackbarProps = () => {
    if (
      serverFeedback === 'This link is not valid.' ||
      serverFeedback === 'Instructions sent.'
    ) {
      return dismissibleSnackbarProps(() => {
        /* @ts-expect-error auto-src: strict-conversion */
        notificationsRef.current.clearSnackbarText()
      })
    }

    return null
  }

  const handleSubmit = () => {
    /* @ts-expect-error auto-src: strict-conversion */
    notificationsRef.current.openProgressDialog()
  }

  return (
    <ThemeProvider>
      <NotificationsCenter
        ref={notificationsRef}
        dialogText="Authenticating..."
        serverFeedback={serverFeedback}
        snackbarProps={snackbarProps()}
      />
      <LoginForm
        onSubmit={handleSubmit}
        ossProvider={ossProvider}
        paths={paths}
        resourceName={resourceName}
        userField={userField}
      />
    </ThemeProvider>
  )
}

export default Login
