// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default function validatePresence(
  /* @ts-expect-error auto-src: noflow */
  name,
  /* @ts-expect-error auto-src: noflow */
  value,
  message = `Please enter your ${name}.`,
) {
  const valid = !!value

  return valid ? null : message
}
