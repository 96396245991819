// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import React, { useRef } from 'react'
import NotificationsCenter from './NotificationsCenter'
import ResetPasswordForm from './ResetPasswordForm'
import ThemeProvider from './ThemeProvider'

type Props = {
  serverFeedback?: string
  paths: any
}

const ResetPassword = ({ serverFeedback, paths }: Props) => {
  const notificationsRef = useRef(null)

  const handleSubmit = () => {
    /* @ts-expect-error auto-src: strict-conversion */
    notificationsRef.current.openProgressDialog()
  }

  return (
    <ThemeProvider>
      <NotificationsCenter
        ref={notificationsRef}
        dialogText="Please wait..."
        serverFeedback={serverFeedback}
      />
      <ResetPasswordForm onSubmit={handleSubmit} paths={paths} />
    </ThemeProvider>
  )
}

export default ResetPassword
