// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import PropTypes from 'prop-types'
import React, { createRef } from 'react'
import validatePresence from '../lib/validatePresence'
import validateEquals from '../lib/validateEquals'
import StyledTextField from './StyledTextField'

export default class ConfirmationField extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    // Identifier used to inform the user of errors on the confirmation field. e.g. password confirmation
    FormHelperTextProps: PropTypes.object,
    helperText: PropTypes.string,
    // Plural of the object being checked for equality. e.g. passwords
    humanIdentifier: PropTypes.string.isRequired,
    label: PropTypes.string,
    name: PropTypes.string,
    onValidate: PropTypes.func.isRequired,
    originalFieldPlural: PropTypes.string.isRequired,
    type: PropTypes.string,
  }

  /* @ts-expect-error auto-src: noflow */
  constructor(props, context) {
    super(props, context)
    /* @ts-expect-error auto-src: noflow */
    this.textFieldRef = createRef()
    this.state = {
      confirmationValue: null,
      originalValue: null,
    }
  }

  componentDidMount() {
    /* @ts-expect-error auto-src: noflow */
    this.input = this.textFieldRef.current.input
  }

  errors = (shouldValidatePresence = false) =>
    (shouldValidatePresence &&
      validatePresence(
        /* @ts-expect-error auto-src: noflow */
        this.props.humanIdentifier,
        /* @ts-expect-error auto-src: noflow */
        this.state.confirmationValue,
      )) ||
    /* @ts-expect-error auto-src: noflow */
    (this.state.originalValue &&
      /* @ts-expect-error auto-src: noflow */
      this.state.confirmationValue &&
      validateEquals(
        /* @ts-expect-error auto-src: noflow */
        this.props.originalFieldPlural,
        /* @ts-expect-error auto-src: noflow */
        this.state.originalValue,
        /* @ts-expect-error auto-src: noflow */
        this.state.confirmationValue,
      ))

  validate = (shouldValidatePresence = false) => {
    /* @ts-expect-error auto-src: noflow */
    this.props.onValidate(this.errors(shouldValidatePresence))
  }

  /* @ts-expect-error auto-src: noflow */
  handleOriginalFieldChange = (event) => {
    this.setState(
      {
        originalValue: event.target.value,
      },
      this.validate,
    )
  }

  /* @ts-expect-error auto-src: noflow */
  handleChange = (event) => {
    this.setState(
      {
        confirmationValue: event.target.value,
      },
      this.validate,
    )
  }

  render() {
    /* @ts-expect-error auto-src: noflow */
    const { FormHelperTextProps, helperText, label, name, type } = this.props

    return (
      <StyledTextField
        FormHelperTextProps={FormHelperTextProps}
        /* @ts-expect-error auto-src: noflow */
        helperText={helperText}
        label={label}
        name={name}
        onBlur={this.handleChange}
        onChange={this.handleChange}
        /* @ts-expect-error auto-src: noflow */
        textFieldRef={this.textFieldRef}
        type={type}
      />
    )
  }
}
