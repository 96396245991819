// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import React, { useState, useRef } from 'react'
import validatePresence from '../lib/validatePresence'
import nameFromField from '../lib/nameFromField'
import AuthenticityToken from './AuthenticityToken'
import ConfirmationField from './ConfirmationField'
import HttpMethodInput from './HttpMethodInput'
import NavigationLink from './NavigationLink'
import PasswordTextField from './PasswordTextField'
import SubmitButton from './SubmitButton'
import StyledTextField from './StyledTextField'

type Props = {
  lastPage?: string
  onSubmit: () => void
  passwordValidationProps: any
  paths: any
  serverErrors: any
}

const EditRegistrationForm = ({
  lastPage = '/',
  onSubmit,
  passwordValidationProps,
  paths,
  serverErrors,
}: Props) => {
  const [errors, setErrors] = useState(serverErrors || {})
  const currentPasswordFieldRef = useRef(null)
  const confirmationFieldRef = useRef(null)
  const passwordFieldRef = useRef(null)

  /* @ts-expect-error auto-src: strict-conversion */
  const addError = (field, message) => {
    if (errors[field] !== message) {
      errors[field] = message
      setErrors(errors)
    }
  }

  const formHasErrors = () => {
    const currentPasswordError = validatePresence(
      'current password',
      /* @ts-expect-error auto-src: strict-conversion */
      currentPasswordFieldRef.current.value,
    )
    /* @ts-expect-error auto-src: strict-conversion */
    const confirmationError = confirmationFieldRef.current.errors(true)
    /* @ts-expect-error auto-src: strict-conversion */
    const isNewPasswordValid = passwordFieldRef.current.validate()

    setErrors({
      current_password: currentPasswordError,
      password_confirmation: confirmationError,
    })

    return currentPasswordError || confirmationError || !isNewPasswordValid
  }

  /* @ts-expect-error auto-src: strict-conversion */
  const handleFormSubmit = (event) => {
    if (formHasErrors()) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      onSubmit()
    }
  }

  /* @ts-expect-error auto-src: strict-conversion */
  const handleRequiredFieldChange = (event) => {
    const { value } = event.target

    if (value) {
      const name = nameFromField(event.target)
      const error = validatePresence(name, value)

      addError(name, error)
    }
  }

  /* @ts-expect-error auto-src: strict-conversion */
  const handleConfirmationValidation = (error) => {
    addError('password_confirmation', error)
  }

  /* @ts-expect-error auto-src: strict-conversion */
  const handlePasswordChange = (event) => {
    /* @ts-expect-error auto-src: strict-conversion */
    confirmationFieldRef.current.handleOriginalFieldChange(event)
  }

  return (
    <form
      action={paths.update}
      autoComplete="off"
      method="post"
      onSubmit={handleFormSubmit}
    >
      <AuthenticityToken />
      <HttpMethodInput />
      <StyledTextField
        FormHelperTextProps={{
          error: Boolean(errors.current_password),
        }}
        /* @ts-expect-error auto-src: non-strict-conversion */
        helperText={errors.current_password}
        inputRef={currentPasswordFieldRef}
        label="Current password"
        name="user[current_password]"
        onBlur={handleRequiredFieldChange}
        onChange={handleRequiredFieldChange}
        type="password"
      />
      <PasswordTextField
        ref={passwordFieldRef}
        initialError={errors.password}
        label="New password"
        name="user[password]"
        onChange={handlePasswordChange}
        /* @ts-expect-error auto-src: non-strict-conversion */
        passwordValidationProps={passwordValidationProps}
      />
      <ConfirmationField
        ref={confirmationFieldRef}
        FormHelperTextProps={{
          error: Boolean(errors.password_confirmation),
        }}
        helperText={errors.password_confirmation}
        humanIdentifier="password confirmation"
        label="Password confirmation"
        name="user[password_confirmation]"
        onValidate={handleConfirmationValidation}
        originalFieldPlural="passwords"
        type="password"
      />
      <SubmitButton label="CHANGE YOUR PASSWORD" />
      <NavigationLink href={lastPage}>Back</NavigationLink>
    </form>
  )
}

export default EditRegistrationForm
