// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* global zxcvbn: readonly */
import PropTypes from 'prop-types'
import React, { createRef } from 'react'
import StyledTextField from './StyledTextField'
import { errorColoring } from '../styles/specificStyles'
import validatePresence from '../lib/validatePresence'
import {
  passwordScoreColors,
  passwordStrengthsList,
  userDefinedWords,
} from './lib'

class PasswordTextField extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    classes: PropTypes.object,
    email: PropTypes.string,
    firstName: PropTypes.string,
    initialError: PropTypes.string,
    label: PropTypes.string,
    lastName: PropTypes.string,
    minLength: PropTypes.number,
    minScore: PropTypes.number,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    textFieldLabel: PropTypes.string,
  }

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    minScore: 0,
  }

  /* @ts-expect-error auto-src: noflow */
  constructor(props, context) {
    super(props, context)
    /* @ts-expect-error auto-src: noflow */
    this.textFieldRef = createRef()
    this.state = {
      error: props.initialError,
      score: 0,
      isValidable: false,
      fieldValue: '',
    }
  }

  componentDidMount() {
    /* @ts-expect-error auto-src: noflow */
    this.input = this.textFieldRef.current.input
  }

  getUserDefinedWords = () => {
    /* @ts-expect-error auto-src: noflow */
    const { firstName, lastName, email } = this.props

    if (!firstName) {
      userDefinedWords.push(firstName)
    }

    if (!lastName) {
      userDefinedWords.push(lastName)
    }

    if (!email) {
      userDefinedWords.push(email)
    }

    return userDefinedWords
  }

  /* @ts-expect-error auto-src: noflow */
  getPasswordStats = (value = this.state.fieldValue) =>
    /* @ts-expect-error auto-src: noflow */
    zxcvbn(value, this.getUserDefinedWords())

  validate = () => {
    /* @ts-expect-error auto-src: noflow */
    const { minScore, textFieldLabel } = this.props
    /* @ts-expect-error auto-src: noflow */
    const { fieldValue } = this.state

    if (fieldValue) {
      return this.getPasswordStats().score >= minScore
    }

    this.setState({
      error: validatePresence(
        textFieldLabel || 'password',
        /* @ts-expect-error auto-src: noflow */
        this.textFieldRef.current.value,
      ),
    })

    return false
  }

  handleFocus = () => {
    this.setState({
      isValidable: true,
    })
  }

  /* @ts-expect-error auto-src: noflow */
  handleChange = (event) => {
    /* @ts-expect-error auto-src: noflow */
    const { onChange } = this.props
    const eventTargetValue = event.target.value

    if (onChange) {
      onChange(event)
    }

    const { score } = this.getPasswordStats(eventTargetValue)

    this.setState({
      error: eventTargetValue && passwordStrengthsList[score],
      score,
      fieldValue: eventTargetValue,
    })
  }

  render() {
    /* @ts-expect-error auto-src: noflow */
    const { name, label } = this.props
    /* @ts-expect-error auto-src: noflow */
    const { fieldValue, error, isValidable, score } = this.state
    const passwordStats = this.getPasswordStats()

    return (
      <>
        <StyledTextField
          FormHelperTextProps={{
            error: Boolean(error),
          }}
          helperColor={passwordScoreColors[passwordStrengthsList[score]]}
          /* @ts-expect-error auto-src: noflow */
          helperText={error}
          label={label || 'Password'}
          name={name}
          onChange={(isValidable && this.handleChange) || null}
          onFocus={this.handleFocus}
          /* @ts-expect-error auto-src: noflow */
          textFieldRef={this.textFieldRef}
          type="password"
        />
        <div style={errorColoring}>
          {(fieldValue &&
            (
              (passwordStats &&
                passwordStats.feedback.warning && [
                  passwordStats.feedback.warning,
                ]) ||
              []
            )
              .concat(passwordStats.feedback.suggestions || [])
              /* @ts-expect-error auto-src: noflow */
              .map((item, index) => (
                <p key={index}>{item.replace(/\.$/, '')}.</p>
              ))) ||
            null}
        </div>
      </>
    )
  }
}

export default PasswordTextField
