// The following eslint-disable was automated from the ts conversion
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import PropTypes from 'prop-types'
import React from 'react'
import generalStyles from '../styles/generalStyles'
import { navigationLink } from '../styles/specificStyles'

/* @ts-expect-error auto-src: noflow */
const NavigationLink = ({ href, children, ...rest }) => (
  <a
    href={href}
    /* @ts-expect-error auto-src: noflow */
    style={{ ...generalStyles.link, ...navigationLink, fontSize: '14px' }}
    {...rest}
  >
    {children}
  </a>
)

NavigationLink.propTypes = {
  children: PropTypes.any,
  href: PropTypes.string,
}
export default NavigationLink
